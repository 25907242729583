import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import { makeStyles } from '@material-ui/core/styles';
import {
  GridList,
  GridListTile,
  GridListTileBar,
  ListSubheader,
  IconButton,
  Paper,
  Tabs,
  Tab,
  AppBar,
  Typography,
  Box
} from '@material-ui/core';
import ReferencesData from '../../data/references';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // width: 500,
    height: 550,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const References = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout bodyClass="page-references">
      <SEO title="References" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Références</h1>
              <p>
                Les projets de TriDyve: applications web, et plugins pour le secteur de la Construction.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className={classes.root}>
          <AppBar
            position="static"
            color="default"
            className="app-bar"
          >
            <Tabs
              className="tabs"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
            >
              <Tab label="Applications Web " {...a11yProps(0)} />
              <Tab label="Plugins" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <GridList cellHeight={350} spacing={2} className={classes.gridList}>
              {ReferencesData.webApps.map(reference => (
                <GridListTile
                  key={reference.img}
                  cols={reference.featured ? 2 : 1}
                  rows={reference.featured ? 2 : 1}
                  className={reference.video === ""? '': "reference-video-container" }
                > 
                  {reference.video === "" ?
                    <div>
                      <a className="reference-img-container" target="_blank" href={reference.img}>
                        <img className="reference-img" src={reference.img} alt={reference.title} />
                      </a>
                      <GridListTileBar
                        title={reference.title}
                        subtitle={<span>by: {reference.author}</span>}
                      />
                    </div>
                    :
                    <div>
                      <iframe className="reference-video" title={reference.title} width="560" height="415" src={reference.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                      </iframe>
                      <GridListTileBar
                        title={reference.title}
                        subtitle={<span>by: {reference.author}</span>}
                      /> 
                    </div>
                  }
                </GridListTile>
              ))}
            </GridList>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GridList spacing={2} className={classes.gridList}>
              {ReferencesData.plugins.map(reference => (
                <GridListTile
                  key={reference.img}
                  cols={reference.featured ? 2 : 1}
                  rows={reference.featured ? 2 : 1}
                  className={reference.video === ""? '': "reference-video-container" }
                > 
                  {reference.video === "" ?
                    <div>
                      <a className="reference-img-container" target="_blank" href={reference.img}>
                        <img className="reference-img" src={reference.img} alt={reference.title} />
                      </a>
                      <GridListTileBar
                        title={reference.title}
                        subtitle={<span>by: {reference.author}</span>}
                      />
                    </div>
                    :
                    <div>
                      <iframe className="reference-video" title={reference.title} width="560" height="415" src={reference.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                      </iframe>
                      <GridListTileBar
                        title={reference.title}
                        subtitle={<span>by: {reference.author}</span>}
                      /> 
                    </div>
                  }
                </GridListTile>
              ))}
            </GridList>
          </TabPanel>
        </div>
      </div>
    </Layout>
  );
};

export default References;


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      className="tab-panel"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tab-panel" p={3}>{children}</Box>}
    </Typography>
  )
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}